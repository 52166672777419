<template>
  <div class="pre-body pre-body-md">
    <div class="pre-subhead pre-subhead-border centered">
      <div class="pre-divider"></div>
      <div class="pre-subhead--copy">Technical Questions</div>
      <div class="pre-divider"></div>
    </div>

    <div class="pre-pad"></div>
    <div class="" v-for="(category, catId) in allFaqs" :key="catId">
      <!-- <div class="pre-headline text-center mb-5">{{ category.category }}</div> -->
      <div v-for="(faq, faqId) in category.faqs" :key="faqId" class="mb-5">
        <div class="pre-subhead">
          {{ faq.question }}
        </div>
        <div class="">
          <p
            v-for="(answer, answerId) in faq.answer.description"
            :key="answerId"
          >
            {{ answer }}
          </p>
          <div v-if="faq.answer.subDescriptions" class="pl-4 mb-3">
            <div
              class="mb-2"
              v-for="(subItem, subItemId) in faq.answer.subDescriptions"
              :key="subItemId"
            >
              {{ subItem }}
            </div>
          </div>
          <div v-if="faq.answer.end">
            <p v-for="(answer, answerId) in faq.answer.end" :key="answerId">
              {{ answer }}
            </p>
          </div>
        </div>
      </div>
      <div class="pre-divider my-5"></div>
    </div>
  </div>
</template>
<script>
import { technicalFaqs } from "../constants/faqsConstants";
export default {
  name: "TechnicalFAQs",
  data() {
    return {
      allFaqs: technicalFaqs,
    };
  },
};
</script>
