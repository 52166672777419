const faqs = [
  {
    category: "Tickets",
    faqs: [
      {
        question: "Where do I find my ticket?",
        answer: {
          description: [
            "You will receive an email confirmation from tickets@universe.com shortly after booking your tickets. If you cannot find the message in your inbox, please check your spam folder or contact support@universe.com for assistance (do not make a second booking). ",
          ],
        },
      },
      {
        question: "I booked more than 1 ticket - what do I do?",
        answer: {
          description: [
            "We love a good ol’ team player! If you booked tickets for yourself and others, please forward them all communications to allow them to join the show website and log in to the event. If you are an adult attending with children, please don’t forward them any communications as you will be logging on for them. ",
          ],
        },
      },
      {
        question: "I didn’t get the email with the link/password.",
        answer: {
          description: [
            "If you are the ticket buyer, please check for an email sent from tickets@universe.com in your inbox to find the email with the link and the password to join the show website which will lead you directly to the event. If you are not the ticket buyer, please ask them to forward you the email.",
          ],
        },
      },
      {
        question: "Do I need to do anything with my ticket?",
        answer: {
          description: [
            "Yes! You will need the ticket ID ready for when you log into the event. There is a different ticket ID for each guest participating. ",
          ],
        },
      },
    ],
  },
  {
    category: "Accessing the Event",
    faqs: [
      {
        question: "What do I need in order to access the event? ",
        answer: {
          description: [
            "Every ticket holder must have the following items in order to take part in the event:",
          ],
          subDescriptions: [
            "●	Laptop or computer with built in audio/video capabilities (mobile devices are not supported)",
            "●	Headphones plugged into the laptop or computer to hear 360 degree sound effects ",
            "●	The latest version of Chrome, Firefox, Edge or Safari Browser (Internet Explorer is not supported)",
            "●	Strong internet connection (outbound 3.2 Mbps, inbound 2.6 Mbps - 3.2 Mbps)",
            "●	Quad core processor with 4 GB RAM minimum (8GB+ RAM recommended)",
            "●	You’ll also need a paper and a pen - and be prepared to gather items from around your home!",
          ],
          end: [
            "If you do not have these items you won’t be able to participate in the event.",
          ],
        },
      },
      {
        question:
          "Are there any other technical requirements to ensure I have the best experience?",
        answer: {
          description: [
            "In order to have the best experience we recommend the following:",
          ],
          subDescriptions: [
            "●	A relatively current laptop or computer with the following:",
            "○	OS: Windows 10 or Mac OS Catalina",
            "○	Processor: Quad Core Intel i7 (equivalent) or better",
            "○	Memory: 8 GB RAM",
            "○	Graphics: Equivalent of a NVIDIA 1080 / AMD Radeon Pro 590 or better",
            "○	Storage: 1 GB available space",
            "●	Broadband internet connection recommended",
            "●	Make sure your WIFI is at full strength and sit in the same room as your WIFI router (keep other WIFI usage in your house to a minimum)",
            "●	Use an ethernet cable to connect to the internet where possible ",
            "●	Close all other browser tabs, video connections and background applications before opening the experience ",
            "●	Make sure you choose the correct camera and microphone settings, you’ll be asked to check this before the experience begins ",
            "●	Don’t use a tablet or mobile phone to access the experience, you must use a laptop or computer as the experience is designed for bigger screens ",
          ],
        },
      },
      {
        question: "Is this event online?",
        answer: {
          description: [
            "This is an online virtual immersive experience which will be broadcast via an Online Live Stream hosted by Secret Cinema in GMT and EST Time Zones in the English language. The event is NOT at a physical location.",
          ],
        },
      },
      {
        question: "How do I access the event? ",
        answer: {
          description: [
            "You will be emailed with a link to the show website where you will need to create an account or log in using your existing Secret Cinema account. Make sure to forward this on to your friends who are attending with you. ",
            "Once you have logged in or registered an account you will be able to access the show website. If you have logged on in advance of your ticket time, you can use this opportunity to explore the website.",
            "When the event is starting you will see a countdown appear and you will be prompted to join the event. ",
          ],
        },
      },
      {
        question: "How do my children access the event? ",
        answer: {
          description: [
            "Children aged between 12 and 17 are not permitted to create their own account on the website, this is so that we don’t collect their data. ",
            "Adult/guardian ticket holders who are 18+ should follow these steps:",
          ],
          subDescriptions: [
            "1.	Create an account or log in to your existing Secret Cinema account",
            "2.	Insert your details ",
            "3.	Click ‘Create Child Profile’",
            "4.	Log into your child's device ",
            "5.	Select the Child Profile to use ",
          ],
        },
      },
      {
        question: "How many teams are there?",
        answer: {
          description: [
            "There are 6 people in total in a team. Each event will have 10 teams maximum. ",
          ],
        },
      },
      {
        question: "Can other people see and hear me?",
        answer: {
          description: [
            "Everyone in your team will be able to see and hear you, and at other points in the game you will be seen by other teams but not heard. ",
            "If at any point you don’t want to be seen or heard, you can turn off your video and mute your microphone in the experience. ",
          ],
        },
      },
    ],
  },
  {
    category: "Times",
    faqs: [
      {
        question:
          "My ticket is in GMT time zone but I’m in a different timezone, what does this mean?",
        answer: {
          description: [
            "The events are listed in GMT and EST time zones but if you are joining from elsewhere please make sure that you are joining at the correct time.",
          ],
        },
      },
      {
        question: "What time does the event start and finish?",
        answer: {
          description: [
            "Start and finish times are subject to your preferred attendance date and performance. Each performance is 75 minutes long. ",
          ],
        },
      },
      {
        question: "What is the latest time I can arrive? ",
        answer: {
          description: [
            "If you arrive more than 10 minutes after the time on your ticket, you may not be able to enter the event. Make sure you are on time so you don’t miss anything!",
          ],
        },
      },
      {
        question: "My event start time has moved, what does this mean? ",
        answer: {
          description: [
            "Under exceptional circumstances we may move the start time of your event to the next available event by 15 minutes or 30 minutes maximum. You will be informed by email approximately 24 hours in advance and a new ticket will be reissued with the updated time. If you are unable to make the new time slot then you will be entitled to change the date of your ticket to a new event. ",
          ],
        },
      },
    ],
  },
  {
    category: "Accessibillity",
    faqs: [
      {
        question: "Is this event accessible?",
        answer: {
          description: [
            "To allow us to respond to your individual access requirement, please contact access@secretcinema.org before booking and a member of the Customer Service team will be in touch. ",
          ],
        },
      },
      {
        question: "What can I expect?",
        answer: {
          description: ["The Event will include:"],
          subDescriptions: [
            "○	Loud music and noises as part of the experience ",
            "○	Flashing lights and special effects as part of the experience",
            "○	A selection of content without subtitles or captions",
          ],
        },
      },
      {
        question: "What happens if I need assistance during the show?",
        answer: {
          description: [
            "Please alert our moderators who will be able to assist you.",
          ],
        },
      },
    ],
  },
  {
    category: "Further Information",
    faqs: [
      {
        question: "Do I need to dress up?",
        answer: {
          description: [
            "Dressing up is not required but is encouraged. You will find your dress code on the show website. The [merch shop name and link] stocks everything you will require.",
          ],
        },
      },
      {
        question: "House Rules",
        answer: {
          description: [
            "Treat fellow guests and our staff with respect. We do not condone inappropriate language or bad manners. Failure to comply with our house rules will result in your ejection from the experience.",
          ],
        },
      },
      {
        question: "COVID-19",
        answer: {
          description: [
            "The experience can be joined from the comfort of your own home. However, as the experience is partially live we have ensured that our cast and crew are keeping safe by implementing health restrictions in line with Government Guidelines. ",
            "If Government restrictions or recommendations result in the cancellation or delay of the event, we will endeavour to reschedule your ticket. If we cannot reschedule your ticket to another date and session that hasn’t already sold out, we will issue you with a refund. This must be exercised within 2 weeks of the cancelled event. In this instance, please contact support@secretcinema.org for more information and make sure to include your ticket order number in your email. ",
          ],
        },
      },
      {
        question: "Cameras, Telephones, Recording Devices",
        answer: {
          description: [
            "Cameras, mobile telephones, and other recording devices are not permitted to be used during the experience. If you are seen to be using your device, you will be removed from the event. If you need to use your mobile telephone in an emergency, please notify a moderator.",
          ],
        },
      },
    ],
  },
];
export const technicalFaqs = [
  {
    category: "Technical Questions",
    faqs: [
      {
        question: "I can’t log in",
        answer: {
          description: [
            "If you can’t log into the show website, make sure you are using the correct email address and password to enter. You will need to register an account with Secret Cinema to access the event.",
          ],
        },
      },
      {
        question: "I’ve forgotten my password",
        answer: {
          description: [
            "If you’ve forgotten your password you can reset it during the login process and you will be emailed with a replacement password. ",
          ],
        },
      },
      {
        question: "I can’t hear anything",
        answer: {
          description: [
            "Make sure that your headphones are correctly plugged into your device and connected to the game. You can see this during the setup process by selecting your audio settings and choosing your headphones. ",
          ],
        },
      },
      {
        question: "I can’t see my video",
        answer: {
          description: [
            "Make sure that your camera is on and connected to the experience. You can do this during the setup process by selecting your camera settings and choosing your device's camera.",
          ],
        },
      },
      {
        question: "I can’t see anything happening on the screen",
        answer: {
          description: [
            "If you can’t see the experience, please contact one of the moderators in the experience who will be able to assist you.",
          ],
        },
      },
      {
        question: "The sound/video quality isn’t very good",
        answer: {
          description: [
            "If your sound is choppy or your video quality is slow, first make sure that you have closed all other applications on your device. Try using an ethernet connection or moving closer to your WIFI router for the best signal. Alternatively you can try tethering from your mobile phone’s 4G or 5G network. ",
          ],
        },
      },
      {
        question: "I was removed from the experience and can’t join again!",
        answer: {
          description: [
            "Our admins are constantly reviewing the behaviour in the experience to ensure that everyone respects the House Rules. If you were removed by a moderator because your behaviour or content was inappropriate, you will not be able to join the experience again.",
          ],
        },
      },
      {
        question: "Someone else is posting inappropriate content/harassing me!",
        answer: {
          description: [
            "We are sorry to hear that another participant’s behaviour affected your experience. We do our best to make our events safe and enjoyable for everyone, and our moderators are constantly reviewing the behaviour in the experience to ensure that everyone respects the House Rules. They will remove participants who behave inappropriately and if you would like to point out a specific participant, please let us know how we can identify them (by name or what you see in their feed).",
          ],
        },
      },
      {
        question: "Is this public? What happens with the experience footage?",
        answer: {
          description: [
            "The experience is restricted to those who have purchased tickets. By agreeing to our terms and conditions in the ticket checkout, you agreed to be filmed for the event, as well as promotional and security purposes. We may also record the Live Stream for reporting and security reasons, as required. If you do not want to be identified in the Live Stream, feel free to wear a mask or a disguise on camera, or simply keep your camera off/pointed away.",
          ],
        },
      },
    ],
  },
];

export default faqs;
